import * as React from "react";
import Layout from "src/components/layout";
import Button from "src/components/button";

const PageComponent = ({ location, copy, pageTitle, lang }) => {
  const prefix = lang === "en" ? "" : `/${lang}`;
  return (
    <Layout pageTitle={pageTitle} location={location} lang={lang}>
      <section className="inner-content mx-auto my-20 text-center">
        <div className="inline-block">
          <Button
            href={`${prefix}/determine-your-health-class`}
            arrow
            invert
            className="py-6"
          >
            {copy.determineHealthClass}
          </Button>
        </div>
        <iframe
          title="quote"
          loading="lazy"
          src="https://www.compulife.net/website/2360_2/quote.html"
          width="100%"
          height="500px"
          allowFullScreen
        ></iframe>
      </section>
    </Layout>
  );
};

export default PageComponent;
