import * as React from "react";
import PageComponent from "src/components/pages/get-a-quote";

const Page = ({ location }) => {
  const copy = {
    determineHealthClass:
      'בידוק את שיערוך ביטוח החיים לו אתה שייך ("דרגת בריאותך")',
  };
  return (
    <PageComponent
      lang="he"
      location={location}
      copy={copy}
      pageTitle="קבל הצעת מחיר"
    />
  );
};

export default Page;
